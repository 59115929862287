<template>
  <div>
    <header class="s-header-contact" style>
      <div class="s-header-content">
        <div class="s-header-column">
          <p class="f-pr">
            Get in touch and
            <br />we'd be happy to help.
          </p>
        </div>
        <div class="s-header-column">
          <ul class="f-pr">
            <li>
              <strong>Outlet Location</strong>
            </li> 
            <li>
              ATC Mall Upper Floor 
            </li>
            <li>Shop# 10 & 11 </li>
            <li> Gulbarg M.M.Alam Road, Lahore, Punjab</li>
          </ul>
          <ul class="f-pr">
            <li>
              <strong>Contact info</strong>
            </li>
            <li>
              <a href="tel:+924235778277">042-35778277</a>
            </li>
            <li>
              <a href="tel:+923133339444">+92 313 3339444</a>
            </li>
            <li>
              <a href="mailto:info@virtualeye.online">info@virtualeye.online</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="s-header-figure rotate">
        <div class="s-header-graphic" style="transform: rotate(0.0063375deg);"></div>
      </div>
      <div class="s-header-background" style="background-color: #F5EBE3" data-background="#ffffff"></div>
    </header>
    <!-- <section class="s-carousel">
      <div class="s-carousel-text" data-effect="block">
        <h2 class="f-hm" style>
          <div
            style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate3d(0px, 0px, 0px); opacity: 1;"
          >Visit us</div>
          <div
            style="display: block; text-align: start; position: relative; transform-origin: 0px 0px; transform: translate3d(0px, 0px, 0px); opacity: 1;"
          >
            <em>at the fairs</em>
          </div>
        </h2>
      </div>
      <div class="s-carousel-container is-landscape" style="height: 260px;">
        <div class="s-carousel-slide" style="visibility: inherit;">
           <div class="s-carousel-item">
            <div>
              <div class="s-carousel-thumbnail">
                <img
                  src="../assets/store_2.jpg"
                  alt="fair berlin jacket"
                />
              </div>
              <div class="s-carousel-info">
                <h3 class="f-st">5-6 July 2020</h3>
                <h4 class="f-hp">The World of HSY</h4>
              </div>
            </div>
          </div>
          <div class="s-carousel-item">
            <div>
              <div class="s-carousel-thumbnail">
                <img
                  src="../assets/store_3.jpg"
                  alt="fair berlin jacket"
                />
              </div>
              <div class="s-carousel-info">
                <h3 class="f-st">5-6 July 2020</h3>
                <h4 class="f-hp">The World of HSY</h4>
              </div>
            </div>
          </div>
          <div class="s-carousel-item">
            <div>
              <div class="s-carousel-thumbnail">
                <img
                  src="../assets/store_1.jpg"
                  alt="people viewing jacket"
                />
              </div>
              <div class="s-carousel-info">
                <h3 class="f-st">19-21 July 2020</h3>
                <h4 class="f-hp">The World of HSY</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-carousel-background" data-background="#FFFFFF"></div>
    </section> -->
  </div>
</template>

<script>
export default {};
</script>

<style>
.s-carousel{
  background: #fff;
  padding: 16.25vw 0;
  margin: 0;
}
.rotate {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 8000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 100s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
    @-ms-keyframes spin { 
        from { 
            -ms-transform: rotate(0deg); 
        } to { 
            -ms-transform: rotate(360deg); 
        }
    }
    @-moz-keyframes spin { 
        from { 
            -moz-transform: rotate(0deg); 
        } to { 
            -moz-transform: rotate(360deg); 
        }
    }
    @-webkit-keyframes spin { 
        from { 
            -webkit-transform: rotate(0deg); 
        } to { 
            -webkit-transform: rotate(360deg); 
        }
    }
    @keyframes spin { 
        from { 
            transform: rotate(0deg); 
        } to { 
            transform: rotate(360deg); 
        }
    }
@media only screen and (max-width: 580px){
  .s-carousel-container {
      height: 140vh !important;
  }
  .s-carousel-item{
    margin-bottom: 30px;
  }
  .s-carousel-slide:nth-child(3n+1) .s-carousel-item:nth-child(3n+3){
    margin-left: 0;
  }
}

</style>